import React, { useEffect, useState } from "react";
import "../../css/pages/dashboard.css";
import DashboardTabs from "./DashboardTabs";
import PageHeader from "../../components/PageHeader";
import { postRequest } from "services/api";

const Dashboard = () => {
  const [navData, setNavData] = useState([]);

  const getNavData = async () => {
    const userData = JSON.parse(localStorage.getItem("userCredential"));
    try {
      const headers = {
        Authorization: `Bearer ${userData.token}`,
      };
      const payLoad = { userId: userData.Id };
      if(JSON.parse(localStorage.getItem("navMenu"))) {
        setNavData(JSON.parse(localStorage.getItem("navMenu")));
        return
      }
      const res = await postRequest("User/GetUserNavMenus", payLoad, headers);
      localStorage.setItem('navMenu', JSON.stringify(res.data.Payload))
      setNavData(res.data.Payload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNavData();
  }, []);

  const checkMenuPermission = (name) => {
    return navData && navData.some((menu) => menu.menuName.includes(name));
  };

  return (
    <div className="dashboard-container">
      {checkMenuPermission("Dashboard") && <PageHeader title="Dashboard"/>}
      <DashboardTabs navData={navData}/>
    </div>
  );
};

export default Dashboard;
