import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { postRequest } from "services/api";
import "../../css/pages/bolReports.css";
import PDFViewer from "components/PDFViewer";
import SignatureCanvas from "react-signature-canvas";
import { PDFDocument } from "pdf-lib";
import CheckIcon from "@mui/icons-material/Check";
import { apiEndPoint } from "services";

const columns = [
  {
    field: "OrderNumber",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "IGD Order",
  },
  {
    field: "BLNumber",
    width: 180,
    renderCell: (params) => {
      if (params.row.signedBOLFile || params.row.sourceBOLFile) {
        return <Link href="#">{params.value && params.value.toString()}</Link>;
      } else {
        return params.value && params.value.toString();
      }
    },
    headerClassName: "headingbg-color",
    headerName: "BOL Number",
  },
  {
    field: "MBOLNumber",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "MBOL Number",
  },
  {
    field: "UploadMultiFile",
    renderCell: (params) => (
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 0, mb: 0, fontSize: "11px" }}
        //onClick={() => setFormDialog(true)}
      >
        Upload Files
      </Button>
    ),
    width: 120,
    headerClassName: "headingbg-color",
    headerName: "Upload File",
  },
  {
    field: "digitalSign",
    renderCell: (params) => {
      if (params.row.sourceBOLFile) {
        return (
          <Button variant="contained" sx={{ mt: 0, mb: 0, fontSize: "11px" }}>
            Sign Here
          </Button>
        );
      }
    },

    width: 120,
    headerClassName: "headingbg-color",
    headerName: "Digital Signature",
  },
  // { field: 'BLNumber', headerClassName: "headingbg-color", width: 180, headerName: 'BL Number' },
  // { field: 'BLStatus', headerClassName: "headingbg-color", headerName: 'BL Status' },
  {
    field: "OrderType",
    headerClassName: "headingbg-color",
    width: 200,
    headerName: "Order Type",
  },

  {
    field: "CarrierCode",
    width: 200,
    headerClassName: "headingbg-color",
    headerName: "SCAC",
  },
  {
    field: "CarrierName",
    width: 200,
    headerClassName: "headingbg-color",
    headerName: "Carrier Name",
  },
  // { field: 'FreightPaymentType', headerClassName: "headingbg-color", headerName: 'Freight Payment Type' },
  // { field: 'sourceBOLFile', width: 200, headerClassName: "headingbg-color", headerName: 'source BOL File' },

  {
    field: "Tracking_PROnumber",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Tracking/PRO",
  },
  // {
  //     field: 'UploadFile',
  //     renderCell: (params) => (
  //         <Link href="#">
  //             <input type="file" accept=".pdf" onChange={handleFileChange} />
  //             Upload File</Link>),
  //     width: 280, headerClassName: "headingbg-color", headerName: 'Upload File'
  // },
];

const EsignBolTable = () => {
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [orderNo, setOrderNo] = useState("");
  const [isSigned, setIsSigned] = useState(0);
  const [signedFileName, setsignedFileName] = useState("");
  const [bolNo, setBolNo] = React.useState("");
  const [mBolNo, setMbolNo] = React.useState("");
  const [openSignature, setOpenSignature] = useState(false);
  const [formDialog, setFormDialog] = useState(false);
  const signatureCanvasRef = useRef(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dataSaved, setDataSaved] = useState(false);
  const [bolError, setBolError] = useState(false);
  const [mBolError, setMbolError] = useState(false);
  const [mbolFile, setmBolFile] = useState(null);
  const [fileBols, setFileBols] = useState(null);

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      carrier: "all",
      status: "all",
    };
    const response = await postRequest(
      "user/getRprt_billOfLadingDtls",
      payload,
      headers
    );
    setData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [startDate, endDate, userSetting]);

  const rows = data.map((c, index) => ({
    id: index,
    OrderNumber: c.OrderNumber,
    BLNumber: c.BLNumber,
    BLStatus: c.BLStatus,
    OrderType: c.OrderType,
    BillTo: c.BillTo,
    ShipTo: c.ShipTo,
    CarrierCode: c.CarrierCode,
    CarrierName: c.CarrierName,
    FreightPaymentType: c.FreightPaymentType,
    Tracking_PROnumber: c.Tracking_PROnumber,
    sourceBOLFile: c.sourceBOLFile,
    SealNumber: c.SealNumber,
    isBOLSigned: c.isBOLSigned,
    signedBOLFile: c.signedBOLFile,
    MBOLNumber: c.MBOLNumber,
  }));

  const handleCellSelectionModelChange = useCallback((newModel) => {
    Object.entries(newModel).forEach(([id, fields]) => {
      const updatedRow = apiRef.current.getRow(id);
      if (fields.BLNumber) {
        setOrderNo(updatedRow.OrderNumber);
        setIsSigned(updatedRow.isBOLSigned);
        const filepath = `${apiEndPoint}/`;
        setsignedFileName(filepath + updatedRow.signedBOLFile);
        if (updatedRow.isBOLSigned) {
          setOpen(true);
        } else {
          if (updatedRow.sourceBOLFile) {
            setsignedFileName(filepath + updatedRow.sourceBOLFile);
            setOpen(true);
          }
        }
      } else if (fields.digitalSign) {
        if (!updatedRow.sourceBOLFile) {
          return;
        }
        setOrderNo(updatedRow.OrderNumber);
        setBolNo(updatedRow.BLNumber);
        setMbolNo(updatedRow.MBOLNumber);
        setIsSigned(updatedRow.isBOLSigned);
        const filepath = `${apiEndPoint}/`;
        setsignedFileName(filepath + updatedRow.sourceBOLFile);
        if (updatedRow.isBOLSigned) {
          setOpenSignature(true);
        } else {
          setsignedFileName(filepath + updatedRow.sourceBOLFile);
          setOpenSignature(true);
        }
      } else if (fields.UploadMultiFile) {
        setOrderNo(updatedRow.OrderNumber);
        setBolNo(updatedRow.BLNumber);
        setMbolNo(updatedRow.MBOLNumber);
        setIsSigned(updatedRow.isBOLSigned);
        const filepath = `${apiEndPoint}/`;
        setsignedFileName(filepath + updatedRow.signedBOLFile);
        setFormDialog(true);
        setBolError(false);
        setError(false);
      } else {
        return;
      }
    });

    // setCellSelectionModel(newModel);
  }, []);

  const handleClearSignature = () => {
    signatureCanvasRef.current?.clear();
  };

  const handleSavePDF = async () => {
    if (!signatureCanvasRef.current || signatureCanvasRef.current.isEmpty()) {
      alert("Please provide a signature.");
      return;
    }
    const signatureImage = signatureCanvasRef.current.toDataURL();
    var pdfToSign = signedFileName;

    const existingPdfBytes = await fetch(pdfToSign).then((res) =>
      res.arrayBuffer()
    );
    const bytes = new Uint8Array(existingPdfBytes);
    const pdfDoc = await PDFDocument.load(bytes);

    const pages = await Promise.all(
      Array.from(Array(pdfDoc.getPageCount()).keys()).map(async (pageIndex) => {
        const page = await pdfDoc.getPage(pageIndex);
        const pngImage = await pdfDoc.embedPng(signatureImage);
        const pngDims = pngImage.scale(0.35); // Adjust scale if needed
        const splitName = pdfToSign.split("_");
        if (splitName[3] === "null") {
          page.drawImage(pngImage, {
            x: 380, // Adjust x and y coordinates to position the signature
            y: 150,
            width: pngDims.width,
            height: pngDims.height,
          });
        } else {
          if (pageIndex === 0) {
            page.drawImage(pngImage, {
              x: 380, // Adjust x and y coordinates to position the signature
              y: 110,
              width: pngDims.width,
              height: pngDims.height,
            });
          } else {
            page.drawImage(pngImage, {
              x: 380, // Adjust x and y coordinates to position the signature
              y: 150,
              width: pngDims.width,
              height: pngDims.height,
            });
          }
        }
      })
    );

    const modifiedPdfBytes = await pdfDoc.save();
    const file = new File(
      [modifiedPdfBytes],
      `signedPDF_${orderNo}_${bolNo}.pdf`,
      { type: "application/pdf" }
    );
    uploadSignedFile(file);
  };

  const uploadSignedFile = async (file) => {
    const responseFile = await UploadSignedMbolFile({
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      bolNumber: bolNo,
      mBolNumber: mBolNo,
      file: file,
    });

    if (responseFile) {
      var msg =
        responseFile.data.Message != null
          ? responseFile.data.Message
          : responseFile.data.errors != null
          ? responseFile.data.errors[""][0]
          : responseFile.data.title;
      alert(msg);
      setOpenSignature(false);
      window.location.reload();
    }
  };

  const UploadSignedMbolFile = async (params) => {
    const formData = new FormData();

    formData.append("file", params.file);
    formData.append("bolNumber", params.bolNumber);
    formData.append("mBolNumber", params.mBolNumber);
    formData.append("custCode", params.custCode);
    formData.append("whseCode", params.whseCode);
    let headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user.token}`,
    };

    const response = await postRequest(
      "user/UploadSignedBOLFile",
      formData,
      headers
    );
    return response;
  };

  const handleBolFiles = (event) => {
    setFileBols(event.currentTarget.files[0]);
  };

  const handleMbolFile = (event) => {
    setmBolFile(event.currentTarget.files[0]);
  };

  const isDuplicateBolNumber = (rows, value) => {
    const findDuplicate = rows.filter((row) => row.BLNumber === value);
    if (findDuplicate.length > 1) {
      return true;
    } else {
      return false;
    }
  };

  const isDuplicateMBolNumber = (rows, value) => {
    const findDuplicate = rows.filter((row) => row.BLNumber === value);
    if (findDuplicate.length > 1) {
      return true;
    } else {
      return false;
    }
  };

  const handleBolNumber = (e) => {
    setBolNo(e.target.value);
    if (isDuplicateBolNumber(rows, e.target.value)) {
      setBolError(true);
    } else if (e.target.value === "") {
      setBolError(true);
    } else {
      setBolError(false);
    }
  };

  const handleMbolNumber = (e) => {
    setMbolNo(e.target.value);
    if (isDuplicateMBolNumber(rows, e.target.value)) {
      setMbolError(true);
    } else {
      setMbolError(false);
    }
  };

  const validateForm = () => {
    let isValid = false;
    let message = "";

    if (mBolNo && mbolFile) {
      isValid = false;
    } else if (!mBolNo && !mbolFile) {
      isValid = false;
    } else {
      isValid = true;
      message = "Please fill both Mbol number and upload Mbol files";

      setError(isValid);
      setErrorMessage(message);
      return isValid;
    }

    if (bolNo && fileBols) {
      isValid = false;
    } else {
      isValid = true;
      message = "Please fill both Bol number and upload Bol files";

      setError(isValid);
      setErrorMessage(message);
      return isValid;
    }

    return isValid;
  };

  const save_BOL_MBOL_NUMBER = async () => {
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      orderKey: orderNo,
      bolNumber: bolNo,
      mBolNumber: mBolNo,
    };
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    const response = await postRequest(
      "gpuser/save_ordersBolMbolDtls",
      payload,
      headers
    );
    return response;
  };

  const UploadMultipleUnSignedMbolFile = async (fileMbol, fileBols) => {
    const formData = new FormData();
    formData.append("custCode", userSetting[0].currCustomer);
    formData.append("whseCode", userSetting[0].currWarehouse);
    formData.append("fileMbol", fileMbol);

    formData.append("fileBols", fileBols);

    formData.append("bolNumber", bolNo);
    formData.append("mBolNumber", mBolNo);
    let headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user.token}`,
    };

    const response = await postRequest(
      "user/UploadUnSignedBOLMBOLfile",
      formData,
      headers
    );

    return response;
  };

  const handleUploadMultiFiles = async (e) => {
    e.preventDefault();
    const validate = validateForm();
    if (!validate) {
      const saveBOL_MBOL_number = await save_BOL_MBOL_NUMBER();
      if (saveBOL_MBOL_number) {
        const responseFile = await UploadMultipleUnSignedMbolFile(
          mbolFile,
          fileBols
        );
        if (responseFile) {
          var msg =
            responseFile.Message != null
              ? responseFile.Message
              : responseFile.errors != null
              ? responseFile.errors[""][0]
              : responseFile.title;

          setDataSaved(true);

          window.setTimeout(() => {
            setDataSaved(false);
            setFormDialog(false);
            window.location.reload();
          }, 1500);
        }
      }
    }
  };

  return (
    <div
      style={{
        height: 700,
        width: "100%",
        marginTop: "20px",
        background: "#fff",
      }}
    >
      <DataGridPremium
      loading={isLoading}
        slots={{ toolbar: GridToolbar }}
        apiRef={apiRef}
        //cellSelectionModel={cellSelectionModel}
        onCellSelectionModelChange={handleCellSelectionModelChange}
        cellSelection={true}
        //onRowClick={handlerRowClick}
        columns={columns}
        rows={rows}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sx={{ flex: 1, "& .MuiButton-text": { fontSize:  "14px"} }}
      ></DataGridPremium>

      {open && (
        <Dialog
          id="signDialog"
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "left",
          }}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle
            className="colordivSumHead font-bold"
            id="form-dialog-title"
          >
            Order No: {orderNo}
          </DialogTitle>
          <DialogContent>
            <PDFViewer
              fileName={signedFileName}
              action={() => {}}
              isSigned={isSigned}
              showSign={false}
              orderNo={orderNo}
            />
          </DialogContent>
        </Dialog>
      )}

      {formDialog && (
        <Dialog
          open={formDialog}
          onClose={() => setFormDialog(false)}
          PaperProps={{
            component: "form",
          }}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle>Upload Files</DialogTitle>
          <DialogContent>
            {dataSaved && (
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                Data Saved Succefully
              </Alert>
            )}
            {error && (
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="error">
                {errorMessage}
              </Alert>
            )}
            <Box sx={{ marginBottom: 2 }}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  MBOL Number
                </FormLabel>
                <TextField
                  variant="outlined"
                  placeholder="MBOL Number"
                  onChange={handleMbolNumber}
                  value={mBolNo}
                  fullWidth
                />
              </FormControl>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  MBOL File
                </FormLabel>
                <TextField
                  variant="outlined"
                  placeholder="MBOL File"
                  onChange={handleMbolFile}
                  type="file"
                  inputProps={{
                    multiple: false,
                    accept: "application/pdf",
                  }}
                  fullWidth
                  error={mBolError}
                  helperText={mBolError ? "MBol Number is Duplicate" : ""}
                />
              </FormControl>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  BOL Number *
                </FormLabel>
                <TextField
                  variant="outlined"
                  placeholder="BOL Number"
                  onChange={handleBolNumber}
                  value={bolNo}
                  required
                  fullWidth
                  helperText={bolError ? "Bol Number is Required" : ""}
                  error={bolError}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  BOL File
                </FormLabel>
                <TextField
                  variant="outlined"
                  placeholder="BOL File"
                  onChange={handleBolFiles}
                  type="file"
                  inputProps={{
                    multiple: false,
                    accept: "application/pdf",
                  }}
                  fullWidth
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setFormDialog(false)}
              sx={{ fontSize: "13px" }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              type="button"
              disabled={bolError}
              onClick={handleUploadMultiFiles}
              sx={{ fontSize: "13px" }}
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openSignature && (
        <Dialog
          id="signDialog"
          open={openSignature}
          onClose={() => setOpenSignature(false)}
          aria-labelledby="form-dialog-title"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "left",
          }}
        >
          <DialogTitle
            className="colordivSumHead font-bold"
            id="form-dialog-title"
          >
            Please add your digital signature:-
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                background: "#f5f4f4",
                border: "2px dashed #c2c2c2",
                borderRadius: "8px",
              }}
            >
              <SignatureCanvas
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas",
                }}
                ref={signatureCanvasRef}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClearSignature}>Clear</Button>
            <Button onClick={handleSavePDF}>Save</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default EsignBolTable;
