import React, { useEffect, useState } from "react";
import logoigtwhiteslice from "../../assets/logoigt_white_slice.png";
import profileImg from "../../assets/profile_img.jpg";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";

import "../../css/header.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/user/userSlice";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const [avatarOption, setAvatarOption] = useState(null);
  const { userSetting, user } = useSelector(state => state.user);
  const navigate = useNavigate();
  const handleOpenMenu = (event, setAnchorEl) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (setAnchorEl) => {
    setAnchorEl(null);
  };

  const handleSidebar = () => {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  const handleLogout = () => {
    
    dispatch(logout());
    navigate('/login')
  }

  useEffect(() => {
    if(!localStorage.getItem('userCredential')) {
      navigate('/login')
    }
  }, [user])
  
  

  return (
    <header id="page-topbar">
      <div className="navbar-headers">
        <div className="left-header">
          <div className="navbar-brand-box">
            <a className="logo" href="/">
              <span className="logo-sm">
                <img src={logoigtwhiteslice} alt="" height="32" />
              </span>
            </a>
          </div>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={handleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <div className="customerName">
            {user.userType !== 'Admin' ? userSetting[0].currCustomerName : 'Igd Admin'}
          </div>
        </div>
        <div className="right=header">
          
          <IconButton
            
            onClick={(e) => handleOpenMenu(e, setAvatarOption)}
            sx={{
              ml: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }}
          >
            <Box sx={{fontSize:"16px",marginRight:"10px"}}>
              {user.Email}
            </Box>
            <Avatar alt="Remy Sharp" src={''} />
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={avatarOption}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(avatarOption)}
            onClose={() => handleCloseMenu(setAvatarOption)}
          >
            <MenuItem onClick={handleLogout}>
              <Typography sx={{ textAlign: "center" }}>Logout</Typography>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;
